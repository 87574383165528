.ConfigDevice {
    flex: 0 0 auto;
    width: 350px;
    height: 295px;
    transition: 0.2s !important;
    border-radius: 16px;
    position: relative;
    margin: 10px 10px 10px 0;
}

/*.ConfigDevice:hover {*/
/*    transition: 0.2s;*/
/*    z-index: 1000;*/
/*    height: 240px;*/

/*    transform: scale(1.05);*/
/*    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.4)*/
/*}*/

.ConfigDevice .title {
    height: 60px;
    background-color: #242424;
    display: flex;
    position: relative;
    /*justify-content: center;*/
}


.ConfigDevice .avatar {
    position: absolute;
    height: 40px;
    width: 40px;


}

.ConfigDevice .state {
    border: 5px solid #242424;
    border-radius: 100px;
    height: 53px;
    width: 53px;
    position: relative;
    bottom: -50%;
    left: 5px;
    /*top: 15px;*/
    /*left: 59px;*/
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.ConfigDevice .name {
    position: absolute;
    top: auto;
    bottom: 0;
    margin-bottom: 0;
    margin-top: auto;
    left: 70px;
    color: #FFF;
}

.ConfigDevice .device_type {
    position: absolute;
    bottom: -20px;
    left: 72px;
}

.ConfigDevice .device_id {
    position: absolute;
    top: 5px;
    left: 5px;
}

.ConfigDevice .body_1 {
    padding: 30px 0 0 10px;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    /*justify-content: center;*/
    flex-wrap: wrap;
    width: 100%;
}

.ConfigDevice .inline {
    display: flex;
    flex-direction: row;
}

.ConfigDevice .call {
    position: absolute;
    /*left: 77px;*/
    top: 200px;
    background-color: rgba(0, 0, 0, 0);
    border: 2px solid var(--black_white_cmp);
    color: var(--black_white_cmp);
}

.ConfigDevice .call:hover {
    background-color: #2e7d32;
}

.ConfigDevice .info {
    color: #ADB0BB;
}

.ConfigDevice .other_states {
    margin: 10px 0 0 0;
    padding: 5px;
    display: flex;
    flex-direction: row;

}

.ConfigDevice .InnerIconBattery {
    height: 38px;
    width: 38px;
}

.ConfigDevice .body_1 .input {
    width: 330px;
    /*margin-bottom: 10px;*/
}

.ConfigDevice .body_1 .actions {
    align-self: flex-end;
    position: absolute;
    top: auto;
    bottom: 0;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

