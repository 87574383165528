.groupMng{
    max-height: 100%;
    width: 100%;
    padding: 0 0 0 10px;
}


.groupMng .header_bar {
    width: 100%;
    height: min-content;
    padding: 40px 40px 10px 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
}

.groupMng .body td{
    border-bottom: 0;
}
.groupMng .body .MuiTableFooter-root{
    background-color: rgba(0,0,0,0.2);
    border-radius: 0 0 20px 20px;
    padding: 0 10px 0 0 ;
}
/*.groupMng .body .MuiTableHead-root{*/
/*    background-color: rgba(0,0,0,0.2);*/
/*    border-radius: 0 0 20px 20px;*/
/*}*/
.groupMng .body .MuiTableHead-root th {
    /*background-color: rgba(0,0,0,0.2);*/
}

.groupMng .body .MuiTableHead-root th:first-child{
    border-radius: 20px 0 0 0;
}

.groupMng .body .MuiTableHead-root th:last-child{
    border-radius: 0 20px 0 0;
}
