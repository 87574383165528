.main{
    width: 100%;
    height: 100%;
    display: inline-flex;
    /*flex-direction: row;*/
}

.main .main_content{
    width: 84%;
    height: 100%;
    float: right;
}

.layout {
    width: 15%;
    /*max-width: 15%;*/
    min-width: 215px;
    height: Calc(100% - 14px);
    background-color: #272727;

    /*border-right: 1px solid #424242;*/
    display: flex;
    flex-direction: column;

    border-radius: 30px;
    margin: 7px 0px 7px 7px;
    box-shadow: 0 0 9px 2px rgba(0, 0, 0, 0.5);
    position: relative;
}

.layout .user{
    width: 100%;
    /*height: 100px;*/
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: center;
    padding: 0 0 20px 0;
    background-color: rgb(53,53,53);
    border-radius: 30px 30px 0 0;
}

.user .avatar {
margin: 20px  0px 20px 0px;
    height: 70px;
    width: 70px;
        font-size: 30px;
    background-color: var(--primary_color);

}

.user .name{
    color: #e6e5e8;
    width: min-content;
    max-width: 100%;
    overflow:hidden;
    white-space: break-spaces;
    text-align: center;
}

.user .container{
    color: #e6e5e8;
    margin: 10px 0 0 0;
    width: min-content;
    max-width: 100%;
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.user .group{
    color: #adb0bb;
    width: min-content;
    max-width: 100%;
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.layout .button{
    color: #adb0bb;
    flex: 1;
    max-height: 50px;
    /*width: 100%;*/
    padding: 10px 30px;
    font-weight: 500;
    letter-spacing: 0;
    text-transform: none;
    justify-content: flex-start;
    margin: 10px 10px 0 10px;
}

.layout .logout_button{
    color: #adb0bb;
    flex: 1;
    max-height: 50px;
    /*width: 100%;*/
    padding: 10px 30px;
    font-weight: 500;
    letter-spacing: 0;
    text-transform: none;
    justify-content: flex-start;
    margin: 10px 10px 0 10px;
    position: absolute;
    bottom: 10px;
    width: 90%;
}