.IconTpButton{
margin: 0 10px 0 0;
}
//.IconTpButton:hover{
//  transition: .2s;
//    transform: rotate(50deg);
//}

.IconTpButton[rotation='1'] {
  animation: wobble 1s 1;

}

@keyframes wobble {
  25% {
    transform: rotate(100deg);
  }
  50% {
    transform: rotate(-20deg);
  }
  75% {
    transform: rotate(20deg);
  }
  100% {
    transform: rotate(0deg);
  }
}