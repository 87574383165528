.deviceMng {
    max-height: 100%;
    width: 100%;
    padding: 0 0 0 10px;
    /*display: flex;*/
    /*flex-direction: row;*/
    /*flex-wrap: wrap;*/

}

.deviceMng .header_bar {
    width: 100%;
    height: min-content;
    padding: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
}

.deviceMng .body {

}

.deviceMng .body .filter {
    display: flex;
    flex-direction: row;
    padding: 10px;
}

.deviceMng .body .filter .deviceType {
    margin: 0 10px 0 0;
    padding: 10px;
    width: min-content;
    height: 80px;
    min-height: 1px;
    transition: 0.2s;
}

.deviceMng .body .filter .deviceTypeActive {
    margin: 0 10px 0 0;
    padding: 10px;
    width: min-content;
    height: 80px;
    min-height: 1px;
    background-color: #df221d;
    transition: 0.2s;
}

.deviceMng .body .filter .icon {
    width: 60px;
    height: 60px;
}

/*.deviceMng .body .groupsView {*/
/*    max-height: 100%;*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    flex-wrap: wrap;*/
/*    width: 100%;*/
/*}*/


/*.deviceMng .body .groupsView {*/
/*    !*max-width: 300px;*!*/
/*    overflow: auto;*/
/*    !*display: flex;*!*/
/*    flex-direction: column;*/
/*    width: auto;*/
/*    max-height: Calc(100% - 500px);*/
/*    min-height: 0;*/
/*!*max-height: 100px;*!*/
/*    height: 100%;*/
/*    flex-wrap: wrap;*/
/*    display: block*/
/*}*/

.deviceMng .body .groupsView {
    /*max-width: 300px;*/
    overflow: auto;
    display: flex;
    flex-direction: column;
    width: auto;
    height: 75vh;
}

.add_device_dialog{

}

.add_device_dialog .dialogContent{
    min-height: 482px;
    min-width: 349px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.add_device_dialog .dialogContent .instruction{
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    margin: 0 0 10px 0;
    padding: 10px;
    min-height: 140px;
    width: 100%;
}