.MemberCallPanel{
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    /*background-color: #0288d1;*/
    /*height: 85%;*/
    z-index: 100000;
    flex-direction: column-reverse;
    pointer-events:auto;

}

.PopUpMemberCallPrg {
    /*z-index: 100000;*/
    /*position: absolute;*/
    /*right: 20px;*/
    /*bottom: 20px;*/
    height: 120px;
    width: 600px;
    border-top: 12px solid #00c853;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 0 10px 0 10px;
}

.PopUpMemberCallPrg .button {
    width: 60px;
    height: 60px;
    margin: 0 0 0 10px;
}


.PopUpMemberCallPrg .infoCall {
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: start;
    height: 95px;
    padding: 10px;
    position: absolute;
    left: 10px;
    width: 70%;
    bottom: 5px;
}

.subCallsList {
    position: absolute;
    height: 60vh;
    bottom: 130px;
    right: 0;
    display: flex;
    flex-direction: column-reverse;
    align-content: flex-start;
    flex-wrap: wrap-reverse;
    /*background-color: #e607ff;*/
    pointer-events:none;
    /*background: none;*/

}

.subCallsList .innerSource {
    padding: 10px;
    width: 160px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    pointer-events:auto;
}

.subCallsList .sourceSelected {
    /*background-color: rgba(0, 0, 0, 0.3);*/
    box-shadow: 0 0 9px 5px rgba(223, 34, 29, 1)
}

.subCallsList .sourceOffline {
    /*background-color: rgba(0, 0, 0, 0.3);*/
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.5)
}

.subCallsList .sourceAvailable {
    /*background-color: rgba(0, 0, 0, 0.3);*/
    box-shadow: 0 0 5px 2px rgba(255, 255, 255, 1)
    /*box-shadow: 0 0 5px 2px rgba(56,142,60,1)*/
}

.subCallsList .outerSource {
    margin: 10px 5px 10px 5px;
    padding: 0;
    width: 160px;
    height: 100px;
}