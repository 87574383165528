.contact {
    width: 230px;
    height: 140px;
    transition: 0.2s !important;
    border-radius: 16px;
    position: relative;
    /*margin: 10px;*/

}

.contact:hover {
    transition: 0.2s;
    z-index: 100;
    height: 270px;

    transform: scale(1.05);
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.4)
}

.contact .title {
    height: 60px;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    position: relative;
}

.contact .avatar {
    position: absolute;
    height: 80px;
    width: 80px;


}

.contact .state {
    border: 4px solid;
    border-radius: 100px;
    height: 93px;
    width: 93px;
    position: absolute;
    top: 15px;
    /*left: 59px;*/
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.contact .body_1 {
    padding: 55px 0 0 10px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
    position: relative;
    height: 210px;
}

.contact .inline {
    display: flex;
    flex-direction: row;
}

.contact .call {
    position: absolute;
    /*left: 77px;*/
    bottom: 20px;
    background-color: rgba(0, 0, 0, 0);
    border: 2px solid var(--black_white_cmp);
    color: var(--black_white_cmp);
}

.contact .call:hover {
    background-color: #2e7d32;
}

.contact .info {
    color: #ADB0BB;
}