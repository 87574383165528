html {
  height: 100%;
  width: 100%;

}

body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  /*Background*/
  background: #bdbdbd;
  border-radius: 3px;
  width: 16px;
}

*::-webkit-scrollbar-thumb {
  /*barra di scorrimento*/
  background-color: #424242;
  border-radius: 10px;
  /*width: 10px;*/
  border: 4px solid #bdbdbd;
}

.scroller {
  width: 16px;
  /*background: #bdbdbd;*/
  border-radius: 3px;
  overflow-y: hidden;
  overflow-x: hidden;
  scrollbar-color: #424242 #BDBDBD;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
  width: 100%;
}

:root {
  --primary_color: #FFF;
  --secondary_color: #FFF;
  --black_white_cmp: #FFF

}

