.deviceGroup {
    /*display: flex;*/
    /*flex-direction: row;*/
    /*flex-wrap: nowrap;*/
    flex: 1 0 auto;
    max-width: 100%;
    margin: 10px 10px 0 0;
    min-height: 301px;
    height: fit-content;
}

.deviceGroup .title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.deviceGroup .addButton{}


.deviceGroup .devices {
    /*max-width: 300px;*/
    overflow: auto;
    /*min-width: 100%;*/
    display: flex;
    flex-direction: row;
    width: auto;
}
