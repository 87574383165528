.PopUpGuestCall {
    height: 100%;
    width: 100%;
    max-height: 100vh;
}

.PopUpGuestCall .MuiDialog-paperWidthXl {
    max-width: 4000px;
    max-height: 4000px;
}

.PopUpGuestCall .PopUpBody {
    /*width: 90vw;*/

    /*max-width: 700px;*/

    /*height: 60vh;*/
    /*width: 100%;*/
    width: 100%;

    min-width: 700px;
    display: flex;
    flex-direction: column;
    /*width: 90vh;*/

}

.PopUpGuestCall .PopUpHeader {
    height: 50px;
    width: 100%;

    padding: 30px;
    background-color: rgba(0, 0, 0, 0.3);
    position: relative;
    display: flex;
    flex-direction: row;
    /*justify-content: center;*/
    align-items: center;
    /*align-content: center;*/
    justify-content: space-between;
}

.PopUpGuestCall .PopUpAvatar {
    height: 40px;
    width: 40px;
    /*margin: 15px 0 20px 0;*/
    /*left: 30px;*/
    /*bottom: -20px;*/
}

.PopUpGuestCall .popUpContactName {
    left: 90px;
    /*bottom: 13px;*/
    /*font-size: 1.5rem;*/
}

.PopUpGuestCall .contGroupInfo {
    /*position: absolute;*/
    /*left: 185px;*/
    /*bottom: -25px;*/
    margin: 5px 0 0 0;
    color: #ADB0BB;
}

.PopUpGuestCall .PopUpContent {
    /*padding: 70px 0 0 20px;*/
    /*width: 100%;*/
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    /*height: 86vh;*/
    position: relative;

}

.PopUpGuestCall .PopUpContent .rcw-widget-container {
    position: absolute;
}


.PopUpGuestCall .PopUpContent .rcw-widget-container .active {
    box-shadow: 0 5px 20px 1px #212121;
}


.PopUpGuestCall .PopUpContent .rcw-launcher {
    background-color: #1565c0;
    box-shadow: 0 5px 10px 1px #212121;

}


.PopUpGuestCall .PopUpContent .rcw-messages-container {
    background-color: #212121;
    border-radius: 7px;

}

/*.PopUpGuestCall .PopUpContent .rcw-messages-container::-webkit-scrollbar {*/
/*    width: 16px;*/
/*}*/

/*.PopUpGuestCall .PopUpContent .rcw-messages-container::-webkit-scrollbar-track {*/
/*    !*Background*!*/
/*    background: #bdbdbd;*/
/*    border-radius: 3px;*/
/*    width: 16px;*/
/*}*/

/*.PopUpGuestCall .PopUpContent .rcw-messages-container::-webkit-scrollbar-thumb {*/
/*    !*barra di scorrimento*!*/
/*    background-color: #424242;*/
/*    border-radius: 10px;*/
/*    !*width: 10px;*!*/
/*    border: 4px solid #bdbdbd;*/
/*}*/

.PopUpGuestCall .PopUpContent .rcw-message-text {
    background-color: #757575;
    padding: 7px;
    transition: 0.05s;
}

.PopUpGuestCall .PopUpContent .rcw-message-text:hover {
    /*background-color: #757575;*/
    /*padding: 7px;*/
    transform: scale(1.005);
    box-shadow: 0 5px 10px 1px #212121;
}

.PopUpGuestCall .PopUpContent .rcw-header {
    height: 0;
    padding: 0;
}

.PopUpGuestCall .PopUpContent .rcw-response .rcw-message-text {
    background-color: #3f51b5;
}


.PopUpGuestCall .PopUpContent .rcw-client .rcw-message-text {
    background-color: #0288d1;
}


.PopUpGuestCall .PopUpContent .blackboardPos {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}


.PopUpGuestCall .PopUpContent .video {
    width: 100%;
    height: Calc(100vh - 152px);
    max-height: 100%;

}


.colorPicker {
    position: absolute;
    float: right;
    right: 80px;
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.5);

}

.waitingCall {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    /*height: 400px;*/
    /*width: 400px;*/
    display: flex;
    flex-direction: column;
}

.waitingCall .container {
    position: relative;
    height: 50px;
    width: 135px;
    top: 0;
    right: 0;
    bottom: 0;

    left: 0;
    margin: auto;
    text-align: center;
}

.waitingCall .ball {
    width: 20px;
    position: relative;
    display: inline-block;
    margin: 5px;
    height: 20px;
    border-radius: 50%;
    z-index: 999;
}

.waitingCall .ball-one {
    background-color: #df221dff;
    animation: jump .5s ease 0s infinite alternate;
}

.waitingCall .ball-two {
    background-color: #df221dc8;
    animation: jump .5s ease .15s infinite alternate;
}

.waitingCall .ball-three {
    background-color: #df221d96;
    animation: jump .5s ease .25s infinite alternate;
}

.waitingCall .ball-four {
    background-color: #df221d64;
    animation: jump .5s ease .35s infinite alternate;
}

.waitingCall .shadow {
    position: relative;
    opacity: .1;
    bottom: 20px;
    width: 20px;
    height: 5px;
    border-radius: 50%;
    background-color: black;
    display: inline-block;
    margin: 5px;
}

.waitingCall .shadow-one {
    animation: shrink .5s ease .0s infinite alternate;
}

.waitingCall .shadow-two {
    animation: shrink .5s ease .15s infinite alternate;
}

.waitingCall .shadow-three {
    animation: shrink .5s ease .25s infinite alternate;
}

.waitingCall .shadow-four {
    animation: shrink .5s ease .35s infinite alternate;
}

@keyframes jump {
    0% {
        transform: scaleY(.8);
    }
    100% {
        transform: translateY(-70px);
    }
}

@keyframes shrink {
    100% {
        transform: scaleX(.5);
        opacity: .01;
    }
}

.PopUpContent .subcalls {
    overflow: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 20px;
    top: 20px;
    max-height: 80%;
    padding: 10px;

}

.PopUpContent .outerSource {
    margin: 0 0 20px 0;
    padding: 0;
    width: 160px;
    height: 100px;
}

.PopUpContent .userSource {
    position: absolute;
    bottom: 30px;
    left: 30px;
    /*border: 1px solid ;*/
    object-fit: cover;

    /*margin: 0 0 20px 0;*/
    padding: 0;
    width: 160px;
    height: 100px;
    box-shadow: 0 0 5px 2px rgba(255, 255, 255, 1);
    z-index: 100;

}

.PopUpContent .mainDevSource {
    position: absolute;
    /*border: 1px solid ;*/
    object-fit: cover;

    /*margin: 0 0 20px 0;*/
    padding: 0;
    width: 160px;
    height: 100px;

}

.PopUpContent .innerSource {
    padding: 10px;
    width: 160px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.PopUpContent .sourceSelected {
    /*background-color: rgba(0, 0, 0, 0.3);*/
    box-shadow: 0 0 9px 5px rgba(223, 34, 29, 1)
}

.PopUpContent .sourceOffline {
    /*background-color: rgba(0, 0, 0, 0.3);*/
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.5)
}

.PopUpContent .sourceAvailable {
    /*background-color: rgba(0, 0, 0, 0.3);*/
    box-shadow: 0 0 5px 2px rgba(255, 255, 255, 1)
    /*box-shadow: 0 0 5px 2px rgba(56,142,60,1)*/
}

.PopUpContent .chatPosition {
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: 60px;
    height: 60px;
}