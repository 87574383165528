.toolWindow {
    /*padding: 10px;*/
    position: absolute;
    right: 10px;
    float: left;
    display: flex;
    flex-direction: column;
    /*height: 0;*/
    width: 60px;
    /*height: max-content;*/
    /*margin: Calc(30% - 320px) 0 0 0;*/
    overflow-y: hidden;
    transition: 1s !important;
    height: 45px;
    margin: 0 0 0 10px;
}

.toolWindowExpanded{
    height: 365px;
}

.toolWindow .iconButtonExpanded{
    border-radius: 0;
}


.toolWindow .toolIcon {
    margin: 10px;
    align-self: center;
}

.toolWindow .toolSize {
    height: 150px;
    padding: 10px 0 10px 0;
}

.toolWindow .colorButton {
    /*align-self: center;*/

    position: relative;
    height: 44px;
    min-height: 44px;
    /*border: 3px solid var(--black_white_cmp);*/
    /*border-radius: 40px;*/
}

.toolWindow .colorButton .color {
    position: absolute;
    height: 20px;
    min-height: 20px;
    width: 20px;
    /*margin: 10px;*/
    /*border: 3px solid var(--black_white_cmp);*/
    border-radius: 40px;
    top: 12px;
    left: 20px;
}

.toolWindow .colorButton .circle {
    position: absolute;
    top: 7px;
    left: 15px;
    height: 30px;
    min-height: 30px;
    width: 30px;
    /*margin: 10px;*/
    border: 2px solid var(--black_white_cmp);
    border-radius: 40px;
    background-color: rgba(0, 0, 0, 0);
}

.colorPicker {
    position: absolute;
    margin: Calc(20% - 195px) 0 0 0;
}

.showTool{
    position: absolute;
    float: right;
    display: flex;
    flex-direction: column;
    /*height: 0;*/
    width: 60px;
    height: max-content;
    margin: Calc(30% - 320px) 0 0 0;
}

.showTool .toolIcon {
    margin: 10px;
    align-self: center;

}