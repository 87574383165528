.member_home {
    max-height: 100%;
    width: 100%;
    padding: 10px;
    /*display: flex;*/
    /*flex-direction: row;*/
    /*flex-wrap: wrap;*/

}

.member_home .header_bar {
    width: 100%;
    height: min-content;
    padding: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
}

.member_home .member_home_container{
    /*height: */
    /*display: grid;*/
    /*grid-template-columns: 400px auto;*/
    /*grid-template-rows: 160px 1fr;*/
    /*max-height: 100%;*/
    display: flex;
    height: 100%;
    width: 100%;
}

.member_home .cont_recent_call{
    height: 85vh;
    overflow: scroll;
    min-width: 400px;
    scrollbar-width: none;
}

.member_home .cont_recent_call::-webkit-scrollbar {
    display: none;
}

.member_home .recent_calls{
     /*height: Calc(100% - 111px);*/
     height: 100%;
    display: flex;
    flex-direction: column;
/*min-width: 100px;*/
}
.member_home .right{
    height: 100%;
    width: 100%;
    min-width: 0;
}


.statistics{
    flex: 1 1 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100px;
    /*position: absolute;*/
    width: 100%;
    margin: 0 0 10px 0;

}

.statistics * {
    margin: 0 0 0 10px;
    width: 300px;
}
.member_home .contacts{
    padding: 0 0 0 10px;
    /*min-height: 50%;*/
    height: 68vh;
    flex: 1;
    overflow: scroll;
    scrollbar-width: none;
}

.member_home .inner{
    height: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 230px));
    grid-auto-rows: 190px;
    grid-gap: 10px;
}

.member_home .contacts::-webkit-scrollbar {
    display: none;
}