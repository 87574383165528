.PopUpMemberCall {
    height: 100%;


}

.PopUpMemberCall .PopUpTitle {
    height: 70px;
    padding: 10px;
    display: flex;
    align-items: center;
    background-color: #00c853;
    max-width: 700px;

}

.PopUpMemberCall .PopUpBody {
    max-width: 700px;

    height: 60vh;
    /*width: 100%;*/
    min-width: 700px;
    display: flex;
    flex-direction: row;
}

.PopUpMemberCall .PopUpHeader {
    height: 60vh;
    width: 50%;
    padding: 30px;
    background-color: rgba(0, 0, 0, 0.3);
    position: relative;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: center;
    align-content: center;
}

.PopUpMemberCall .PopUpAvatar {
    height: 130px;
    width: 130px;
    margin: 15px 0 20px 0;
    /*position: absolute;*/
    /*left: 30px;*/
    /*top: 35px;*/
}

.PopUpMemberCall .popUpContactName {
    /*position: absolute;*/
    /*left: 180px;*/
    /*top: 55px;*/
    /*margin: 0 50% 0 50%;*/
}

.PopUpMemberCall .contGroupInfo {
    /*position: absolute;*/
    /*left: 185px;*/
    /*bottom: -25px;*/
    margin: 5px 0 0 0;
    color: #ADB0BB;
}

.PopUpMemberCall .PopUpContent {
    /*padding: 70px 0 0 20px;*/
    width: 50%;
    padding: 20px 20px 40px 20px;
    display: flex;
    flex-direction: column;
}

.PopUpMemberCall .othDevicesList {
    height: Calc(68vh - 30vh);
    /*max-width: 300px;*/
    overflow: auto;
    min-width: 100%;

}

/*.othDevicesList::-webkit-scrollbar {*/
/*    width: 16px;*/
/*}*/

/*.othDevicesList::-webkit-scrollbar-track {*/
/*    !*Background*!*/
/*    background: #bdbdbd;*/
/*    border-radius: 3px;*/
/*    width: 16px;*/
/*}*/

/*.othDevicesList::-webkit-scrollbar-thumb {*/
/*    !*barra di scorrimento*!*/
/*    background-color: #424242;*/
/*    border-radius: 10px;*/
/*    !*width: 10px;*!*/
/*    border: 4px solid #bdbdbd;*/
/*}*/


.PopUpMemberCall .mainDevice {
    min-width: 100%;
    margin: 0 0 20px 0;
    /*max-width: 300px;*/
}

.PopUpMemberCall .popUpAction {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-content: stretch;
    align-items: stretch;
    margin: 15px 0 0 0;
    align-self: start;
}

.PopUpMemberCall .callDescription {
    margin: 50px 0 20px 0;


}