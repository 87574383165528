.device {
    flex: 0 0 auto;
    width: 230px;
    height: 190px;
    transition: 0.2s !important;
    border-radius: 16px;
    position: relative;
    /*margin: 10px;*/
}

.device:hover {
    transition: 0.2s;
    z-index: 1000;
    height: 240px;

    transform: scale(1.05);
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.4)
}

.device .title {
    height: 60px;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
}

.device .avatar {
    position: absolute;
    height: 80px;
    width: 80px;


}

.device .state {
    border: 4px solid;
    border-radius: 100px;
    height: 93px;
    width: 93px;
    position: absolute;
    top: 15px;
    /*left: 59px;*/
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.device .body_1 {
    padding: 55px 0 0 10px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.device .inline {
    display: flex;
    flex-direction: row;
}

.device .call {
    position: absolute;
    /*left: 77px;*/
    top: 200px;
    background-color: rgba(0, 0, 0, 0);
    border: 2px solid var(--black_white_cmp);
    color: var(--black_white_cmp);
}

.device .call:hover {
    background-color: #2e7d32;
}

.device .info {
    color: #ADB0BB;
}

.device .other_states {
    margin: 10px 0 0 0;
    padding: 5px;
    display: flex;
    flex-direction: row;

}

.device .InnerIconBattery {
    height: 38px;
    width: 38px;
}