.message {
  height: auto;
  width: max-content;
  /*margin: 10px;*/
  padding: 10px 10px 15px 10px;
  background-color: #2196f3;
  max-width: 85%;
  overflow-wrap: break-word;
  position: relative;
  min-width: 50px;

}

.message .text {
  position: relative;
  top: -3px;
  line-height: normal;
}

.message:last-child {
  margin-bottom: 10px;
}

.sent {
  border-radius: 10px 10px 0 10px;
  margin: 10px 10px 0 auto;
  background-color: #2196f3;
}

.received {
  border-radius: 10px 10px 10px 0;
  margin: 10px auto 0 10px;
  background-color: #01579b;

}

.message .messDatetime {
  position: absolute;
  font-size: 0.7em;
  bottom: 2px;
  left: 10px;
}