.signup {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;

    height: 100%;
    flex-direction: column;
}

.signup .container {
    height: 50%;
    min-height: 450px;
    min-width: 350px;
    width: 20%;
    display: flex;
    flex-direction: column;
    padding: 30px 0px 30px 0px ;
    align-items: center;
    justify-items: center;
    align-content: center;
}

.signup .container .logo {
    height: 200px;
    width: 200px;
    margin: 0 0 30px 0;
}

.signup .container .form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.signup .container .confirm {
    margin: 20px 0 0 0;
    /*align-self: center;*/
}
