.callAction {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    z-index: 9000;
}

.callAction .bottom {
    margin: 0 10px 0 0;
}

.callAction .MuiFab-sizeSmall {
    margin: 0 10px 2px 0;
}

.callAction .videoFilters{
    position: absolute;
    padding: 10px;
    height: 0px;
    width: 200px;
    margin: 0 0 20px 0;
    bottom: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    transition: 0.2s;
    transform: translate(-40%, 0);
}
.callAction .videoFiltersOpen{
    height: 220px;

}
.callAction .videoFiltersClose{
    height: 0;
    width: 0;
    padding: 0;
    margin: 0;
    border: 0  ;
}