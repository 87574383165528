.chat {
    position: relative;
    height: 100%;
    width: 100%;
    /*margin: 0 0 0 10px;*/
    z-index: 10000000;

}

.chat .fab {
    height: 60px;
    width: 60px;
}

.chat .chatBody {
    position: absolute;
    width: 350px;
    bottom: 150%;
    right: -10px;
    transition: 0.2s;
    border-radius: 10px 10px 0 0;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;

}

.chat .chatOpen {
    height: 450px;
    box-shadow: 3px 3px 20px 10px rgba(0, 0, 0, 0.2);

}

.chat .chatClose {
    height: 0;

}

.chat .chatBody .chatHeader {
    flex: 0.4;
    /*max-height: 10px;*/
    /*height: 20%;*/
    /*min-height: 50px;*/
    background-color: #2196f3;
    border-radius: 10px 10px 0 0;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    z-index: 10000;
    position: relative;
}

.chat .chatBody .chatHeader .chatContactFrom {
    margin: 0 0 0 10px;
}

.chat .chatBody .messageList {
    width: 100%;
    flex: 5;
    border-radius: 10px 10px 0 0;
    padding: 0;
    overflow: auto;
}

.chat .chatBody .chatList {
    height: max-content;
    /*min-height: 100%;*/
    width: 100%;
}

.chat .chatBody .messageList::-webkit-scrollbar {
    width: 7px;
}

.chat .chatBody .messageList::-webkit-scrollbar-track {
    /*Background*/
    background: rgba(0, 0, 0, 0);
    /*border-radius: 3px;*/
    /*width: 10px;*/
}

.chat .chatBody .messageList::-webkit-scrollbar-thumb {
    /*barra di scorrimento*/
    background-color: #bdbdbd;
    border-radius: 10px;
    /*border: 4px solid rgba(246, 0, 0, 1);*/
}

.chat .chatBody .sendMessage {
    border-radius: 5px 5px 0 0;
    flex: min-content;
    width: auto;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: row;
    padding: 10px 5px 0 13px;
}