.recent_call{
    width: 400px;
    min-width: 400px;
    height: 80px;
    min-height: 80px;
    transition: 0.2s !important;
    border-radius: 16px;
    position: relative;
    margin: 0 0 10px 0;
    display: flex;
    flex-direction: row;

}

.recent_call:hover{
    transition: 0.2s;
    z-index: 9000;
    transform: scale(1.05);
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.4)
}

.recent_call .title{
    width: 50px;
    background-color: rgba(0, 0, 0, 0.2);
    border-right: 2px solid #df221d;
}

.recent_call .avatar{
    position: absolute;
    height: 50px;
    width: 50px;
    left: 25px;
    top: 15px;

}

.recent_call .icon_state{
    position: absolute;
    left: 70px;
    top: 15px;

}

.recent_call_body{
    padding: 10px 0 0  35px ;
    border-left: 2px solid #df221d;
}

.recent_call .inline{
    display: flex;
    flex-direction: row;
    padding: 5px 0 0 0px;
}

.recent_call .call{
    position: absolute;
    right: 15px;
    top: 15px;
    width: 50px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.3);
    color: #FFF;
}

.recent_call .call:hover{
    background-color: #2e7d32;
}