.guest_account {
    max-height: 100%;
    width: 100%;
    padding: 10px;
    /*display: flex;*/
    /*flex-direction: row;*/
    /*flex-wrap: wrap;*/

}

.guest_account .header_bar {
    width: 100%;
    height: min-content;
    padding: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
}

.guest_account .account_container {

}

.guest_account .change_password {
    display: flex;
    flex-direction: column;
    padding: 20px 0 20px 80px;
    position: relative;;
}

.guest_account .textField_password {
    margin: 20px 0 0 20px;
    width: 300px;
}

.guest_account .icon {
    position: absolute;
    left: 15px;
    top: 10px;
    padding: 10px;
    height: 60px;
    width: 60px;
    border-radius: 1000px;
    transition: 0.2s;
}

.guest_account .icon:hover {
    border-radius: 1000px;
    padding: 9px;
}